.main {
  &__hero {
    background-size: cover;
    height: 50vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__content {
    height: auto;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 30rem;
  }
  &__title {
    color: #000;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 56.6px;
    letter-spacing: 0.66px;
  }
  &__cards {
    display: flex;
    padding: 47px 15.75px 45px 15.75px;
    align-items: center;
    gap: 28.55px;
    flex-shrink: 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__card {
    width: 165px;
    height: 370px;
    flex-shrink: 0;
    border-radius: 18.9px;
    border: 1px solid #000;
    padding: 16.8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  &__img {
    width: 8.53438rem;
    height: 11.19313rem;
    // flex-shrink: 0;
  }
  &__name {
    font-size: 13.2px;
    font-style: normal;
    font-weight: 700;
    line-height: 16.98px;
    letter-spacing: 0.66px;
    color: #060606;
  }
  &__prices {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 85%;
  }
  &__regprice {
    color: rgba(0, 0, 0, 0.75);
    font-size: 13.65px;
    font-family: Questrial;
    font-style: normal;
    font-weight: 400;
    line-height: 20.15px;
    letter-spacing: 1.05px;
    text-decoration: line-through;
  }
  &__saleprice {
    color: #000;
    font-size: 16.8px;
    font-family: Questrial;
    font-style: normal;
    font-weight: 400;
    line-height: 24.8px;
    letter-spacing: 1.05px;
  }
}
