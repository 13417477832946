.offer-bar {
  display: flex;
  height: 39.02px;
  padding: 10.5px 74.02px 13.52px 74.22px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-bottom: 1.047px solid rgba(255, 255, 255, 0.08);
  background: #000;

  &__title {
    color: #ffffff;
    text-align: center;
    font-size: 13.2px;
    font-family: Archivo;
    font-weight: 700;
    line-height: 16.98px;
    letter-spacing: 1.05px;
  }
}
