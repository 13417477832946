@import "./Variables";
@import "./Footer";
@import "./NavBar";
@import "./OfferBar";
@import "../styles/pages/Main";
@import "../styles/pages/Contact";
@import "../styles/pages/Faq";
@import "../styles/pages/Refund";
@import "../styles/pages/Terms";
@import "./AddToCart";
@import "../styles/pages/Product";
@import "../styles/pages/Cart";
@import "./SideNavBar";
@import "../styles/pages/TransSuccess";
@import "../styles/pages/About";
@import "../styles/pages/ProductFiltered";
@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: Questrial;
}

a:link,
a:visited {
  color: white;
  // text-align: center;
  text-decoration: none;
  display: inline-block;
}

body {
  background-color: #000;
}

.centerColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.centerRow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.underline {
  text-decoration: underline;
  margin-right: 0.5rem;
}

.fadeInUp {
  animation: 1s fadeInUp;
}

.fadeInLeft {
  animation: 1s fadeInLeft;
}

@keyframes fadeInUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.fade-in-left {
  opacity: 0;
  transform: translateX(-40vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-left.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-right {
  opacity: 0;
  transform: translateX(40vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-right.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fadeIn {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
