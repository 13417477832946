.addtocart {
  width: 119.88px;
  height: 47.25px;

  left: 1px;
  top: 1px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px 0px 0px 1px #fff, 0px 0px 0px 1.2999999523162842px #000;
  color: #000;
}
