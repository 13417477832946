.sidebar {
  position: absolute;
  top: 110px;
  left: -90vw;
  height: 100%;
  width: 90vw;
  transition: left 0.3s ease-in-out;
  background-color: black;
  display: flex;
  flex-direction: column;
  display: flex;
  padding-top: 3rem;

  &__menuItem {
    padding: 11.5px 33.6px;
    font-size: 1.4rem;
  }
}
.sidebar-toggle {
  position: absolute;
  top: 20%;
  right: -100px;
  height: 60px;
  width: 60px;
  z-index: 1;
}
.content {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  height: 100%;
  transition: left 0.3s ease-in-out;
  background-color: lightgray;
}
.sidebar.open {
  left: 0;
}
.content.open {
  left: 15vw;
}
