.terms {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: auto;
  background-color: white;
  padding: 3rem;
  &-content {
    background-color: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 78.75rem;
    font-size: 1.5rem;
    letter-spacing: 0.66px;
  }
  &-title {
    color: #000;
    font-size: 44px;

    font-weight: 700;
    line-height: 56.6px;
    letter-spacing: 0.66px;
    padding: 0 auto;
  }
}
