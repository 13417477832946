.navbar {
  background-color: #000;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__logo {
    margin-left: 2rem;
  }
  &__search {
    display: flex;
    align-items: center;
    margin-right: 2rem;
  }
  &__btn {
    background-color: #000;
    border: none;
  }
  &__menuTablet {
    display: none;
    &-list {
      display: none;
    }
  }
  &__title {
    width: 10rem;
  }
}
.sidebar {
  &__support {
    margin-left: 1.5rem;
  }
  &__item {
    margin: 0.5rem 0;
  }
}

@media (min-width: 1024px) {
  .navbar {
    &__btn {
      display: none;
    }
    &__search {
      display: none;
    }
    &__title {
      display: none;
    }
    &__menuTablet {
      display: flex;
      justify-content: space-around;
      width: 100%;
      align-items: center;
      padding: 0 2rem;
      margin: 1rem auto;
      &-title {
        width: 20rem;
        position: relative;
        right: 7rem;
      }

      &-search {
        // margin-right: 5%;
        display: flex;
        gap: 1rem;
        color: white;
        &-input {
          width: 10rem;
          background-color: #070707;
          border-radius: 5px;
          width: 15rem;

          outline: none;
          border: none;
          border-bottom: 1px solid #fff;
          color: white;
          font-size: 1.2rem;
          font-family: Questrial;
        }
      }

      &-list {
        list-style: none;
        display: flex;
        justify-content: space-between;
        width: 75%;
        margin: 1.5em auto;
      }
    }
  }
  .navbar__menuTablet-item {
    color: white;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 1rem;
    font-family: Questrial;
    font-style: normal;
    font-weight: 400;
    line-height: 1.18125rem;
    letter-spacing: 0.03938rem;
    position: relative;
    display: inline-block;
  }

  .dropbtn {
    display: flex;
  }

  .dropdown {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .dropdown-content {
    display: none;
    position: absolute;

    background-color: #060606;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 1rem;
  }

  .dropdown-content a {
    color: black;
    padding: 2rem 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
  }

  .dropdown:hover .dropdown-content {
    display: flex;
    flex-direction: column;
  }

  .dropdown:hover .dropbtn {
    background-color: transparent;
  }
}
